export const resellerPermissions = (permission, bpSettings) => {
  const permissionEn = [
    {
      id: 1,
      label: "Customer Add",
      value: "customerAdd",
      isChecked: permission?.customerAdd,
      disabled: false,
    },
    {
      id: 2,
      label: "Customer Edit",
      value: "customerEdit",
      isChecked: permission?.customerEdit,
      disabled: false,
    },
    {
      id: 3,
      label: "Add Customer With Mobile Number",
      value: "addCustomerWithMobile",
      isChecked: permission?.addCustomerWithMobile,
      disabled: bpSettings?.addCustomerWithMobile,
    },
    {
      id: 4,
      label: "Edit monthly Fee",
      value: "monthlyFeeEdit",
      isChecked: permission?.monthlyFeeEdit,
      disabled: false,
    },
    {
      id: 5,
      label: "Package Edit",
      value: "customerMikrotikPackageEdit",
      isChecked: permission?.customerMikrotikPackageEdit,
      disabled: false,
      info: "Can your Reseller, Single customer package and Multiple customer package change.",
    },
    {
      id: 6,
      label: "Status Edit",
      value: "customerStatusEdit",
      isChecked: permission?.customerStatusEdit,
      disabled: false,
    },
    {
      id: 25,
      label: "Customer Active",
      value: "customerActive",
      isChecked: permission?.customerActive,
      disabled: false,
      info: "Can Activate Inactive or Expired Customers.",
    },
    {
      id: 7,
      label: "Customer Minus Balance Inactive",
      value: "logicalInactive",
      isChecked: permission?.logicalInactive,
      disabled: false,
      info: "If the option is enabled, the customer can do In-Active if has advance balance and if the option is removed, the customer can do In-Active if has minus balance (Default: False).",
    },
    {
      id: 8,
      label: "Customer Auto Disable Edit",
      value: "customerAutoDisableEdit",
      isChecked: permission?.customerAutoDisableEdit,
      disabled: false,
    },
    // {
    //   id: 9,
    //   label: "Area Delete",
    //   value: "areaDelete",
    //   isChecked: permission?.areaDelete,
    //   disabled: false,
    // },
    // {
    //   id: 10,
    //   label: "Area Add",
    //   value: "areaAdd",
    //   isChecked: permission?.areaAdd,
    //disabled: false,
    // },
    {
      id: 11,
      label: "Bulk Area Edit",
      value: "bulkAreaEdit",
      isChecked: permission?.bulkAreaEdit,
      disabled: !bpSettings?.bulkAreaEdit,
    },
    {
      id: 12,
      label: "Bulk Status Edit",
      value: "bulkCustomerStatusEdit",
      isChecked: permission?.bulkCustomerStatusEdit,
      disabled: !bpSettings?.bulkStatusEdit,
    },
    {
      id: 13,
      label: "Bulk BillingCycle Edit",
      value: "bulkCustomerBillingCycleEdit",
      isChecked: permission?.bulkCustomerBillingCycleEdit,
      disabled: false,
    },
    {
      id: 14,
      label: "Bulk Customer Recharge",
      value: "bulkCustomerRecharge",
      isChecked: permission?.bulkCustomerRecharge,
      disabled: !bpSettings?.bulkCustomerRecharge,
    },
    {
      id: 15,
      label: "Instant Recharge Bill Print",
      value: "instantRechargeBillPrint",
      isChecked: permission?.instantRechargeBillPrint,
      disabled: !bpSettings?.instantRechargeBillPrint,
    },
    {
      id: 16,
      label: "Billing Cycle Edit",
      value: "billingCycleEdit",
      isChecked: permission?.billingCycleEdit,
      disabled: false,
    },
    {
      id: 17,
      label: "Single Customer Number Edit",
      value: "singleCustomerNumberEdit",
      isChecked: permission?.singleCustomerNumberEdit,
      disabled: false,
    },
    // {
    //   id: 18,
    //   label: "Customer Automatic Connection OnOff",
    //   value: "customerAutomaticConnectionOnOff",
    //   isChecked: permission?.customerAutomaticConnectionOnOff,
    //   disabled: false,
    // },
    {
      id: 19,
      label: "Daily Recharge Feature",
      value: "dailyRechargeFeature",
      isChecked: permission?.dailyRechargeFeature,
      disabled: !bpSettings?.dailyRechargeFeature,
      info: "After paying the customer's bill, the bill amount will not be deducted from the reseller's balance immediately. The daily amount of the customer's monthly bill will be deducted from the daily reseller balance.",
    },
    {
      id: 20,
      label: "Minus Balance",
      value: "minusBalance",
      isChecked: permission?.minusBalance,
      disabled: !bpSettings?.resellerMinusBalance,
    },
    // {
    //   id: 21,
    //   label: "Bulk Payment Status Edit",
    //   value: "bulkPaymentStatusEdit",
    //   isChecked: permission?.bulkPaymentStatusEdit,
    //   disabled: !bpSettings?.bulkPaymentStatusEdit,
    // },
    {
      id: 22,
      label: "Customer Delete",
      value: "customerDelete",
      isChecked: permission?.customerDelete,
      disabled: false,
    },
    {
      id: 23,
      label: "Daily Reseller Customer Auto Connection",
      value: "dailyResellerCustomerAutoConnection",
      isChecked: permission?.dailyResellerCustomerAutoConnection,
      disabled: false,
    },
    {
      id: 24,
      label: "Customer Recharge",
      value: "customerRecharge",
      isChecked: permission?.customerRecharge,
      disabled: false,
    },
    {
      id: 25,
      label: "Bulk Customer Package Update",
      value: "bulkCustomerPackageUpdate",
      isChecked: permission?.bulkCustomerPackageUpdate,
      disabled: !bpSettings?.bulkPackageEdit,
    },
    {
      id: 26,
      label: "Promise Date",
      value: "promiseDate",
      isChecked: permission?.promiseDate,
      disabled: false,
    },
  ];

  const permissionBn = [
    {
      id: 1,
      label: "কাস্টমার অ্যাড",
      value: "customerAdd",
      isChecked: permission?.customerAdd,
      disabled: false,
    },
    {
      id: 2,
      label: "কাস্টমার এডিট",
      value: "customerEdit",
      isChecked: permission?.customerEdit,
      disabled: false,
    },
    {
      id: 3,
      label: "মোবাইল নাম্বার সহ কাস্টমার অ্যাড",
      value: "addCustomerWithMobile",
      isChecked: permission?.addCustomerWithMobile,
      disabled: bpSettings?.addCustomerWithMobile,
    },

    {
      id: 4,
      label: "মাসিক ফি এডিট",
      value: "monthlyFeeEdit",
      isChecked: permission?.monthlyFeeEdit,
      disabled: false,
    },
    {
      id: 5,
      label: "প্যাকেজ এডিট",
      value: "customerMikrotikPackageEdit",
      isChecked: permission?.customerMikrotikPackageEdit,
      disabled: false,
      info: "আপনার রিসেলার, একটি গ্রাহকের প্যাকেজ এবং একাধিক গ্রাহকের প্যাকেজ পরিবর্তন করতে পারবেন।",
    },
    {
      id: 6,
      label: "স্টাটাস এডিট",
      value: "customerStatusEdit",
      isChecked: permission?.customerStatusEdit,
      disabled: false,
    },
    {
      id: 25,
      label: "গ্রাহক এক্টিভ",
      value: "customerActive",
      isChecked: permission?.customerActive,
      disabled: false,
      info: "ইন-এক্টিভ অথবা এক্সপায়ার্ড গ্রাহকদের এক্টিভ করতে পারবে।",
    },
    {
      id: 7,
      label: "গ্রাহক মাইনাস ব্যালেন্স ইন-এক্টিভ",
      value: "logicalInactive",
      isChecked: permission?.logicalInactive,
      disabled: false,
      info: "অপশনটি দিলে, গ্রাহকের অগ্রিম ব্যালেন্স থাকলে ইন-এক্টিভ করতে পারবে এবং অপশনটি তুলে নিলে, গ্রাহকের মাইনাস ব্যালেন্স থাকলে ইন-এক্টিভ করতে পারবে (ডিফল্ট: ফেলসে)।",
    },
    {
      id: 8,
      label: "কাস্টমার অটো ডিসেবল এডিট",
      value: "customerAutoDisableEdit",
      isChecked: permission?.customerAutoDisableEdit,
      disabled: false,
    },
    {
      id: 9,
      label: "এরিয়া ডিলিট",
      value: "areaDelete",
      isChecked: permission?.areaDelete,
      disabled: false,
    },
    // {
    //   id: 10,
    //   label: "এড এরিয়া",
    //   value: "areaAdd",
    //   isChecked: permission?.areaAdd,
    //disabled: false,
    // },
    {
      id: 11,
      label: "বাল্ক এরিয়া এডিট",
      value: "bulkAreaEdit",
      isChecked: permission?.bulkAreaEdit,
      disabled: !bpSettings?.bulkAreaEdit,
    },
    {
      id: 12,
      label: "বাল্ক স্ট্যাটাস এডিট",
      value: "bulkCustomerStatusEdit",
      isChecked: permission?.bulkCustomerStatusEdit,
      disabled: !bpSettings?.bulkStatusEdit,
    },
    {
      id: 13,
      label: "বাল্ক বিলিং সাইকেল এডিট",
      value: "bulkCustomerBillingCycleEdit",
      isChecked: permission?.bulkCustomerBillingCycleEdit,
      disabled: false,
    },
    {
      id: 14,
      label: "বাল্ক গ্রাহক রিচার্জ",
      value: "bulkCustomerRecharge",
      isChecked: permission?.bulkCustomerRecharge,
      disabled: !bpSettings?.bulkCustomerRecharge,
    },
    {
      id: 15,
      label: "ইনস্ট্যান্ট রিচার্জ বিল প্রিন্ট",
      value: "instantRechargeBillPrint",
      isChecked: permission?.instantRechargeBillPrint,
      disabled: !bpSettings?.instantRechargeBillPrint,
    },
    {
      id: 16,
      label: "বিলিং সাইকেল এডিট",
      value: "billingCycleEdit",
      isChecked: permission?.billingCycleEdit,
      disabled: false,
    },
    {
      id: 17,
      label: "সিঙ্গেল কাস্টমার নম্বর এডিট",
      value: "singleCustomerNumberEdit",
      isChecked: permission?.singleCustomerNumberEdit,
      disabled: false,
    },
    // {
    //   id: 18,
    //   label: "গ্রাহক অটোমেটিক সংযোগ অনঅফ",
    //   value: "customerAutomaticConnectionOnOff",
    //   isChecked: permission?.customerAutomaticConnectionOnOff,
    //   disabled: false,
    // },
    {
      id: 19,
      label: "দৈনিক রিচার্জ ফিচার",
      value: "dailyRechargeFeature",
      isChecked: permission?.dailyRechargeFeature,
      disabled: !bpSettings?.dailyRechargeFeature,
      info: "গ্রাহকের বিল পরিশোধ করার পর, তৎক্ষণাৎ বিলের টাকা রিসেলারের ব্যালেন্স থেকে কাটা হবে না। দৈনিক রিসেলার ব্যালেন্স থেকে গ্রাহকের মাসিক বিলের, দৈনিক টাকা কেটে নেওয়া হবে।",
    },
    {
      id: 20,
      label: "মাইনাস ব্যালান্স",
      value: "minusBalance",
      isChecked: permission?.minusBalance,
      disabled: !bpSettings?.resellerMinusBalance,
    },
    // {
    //   id: 21,
    //   label: "বাল্ক পেমেন্ট স্ট্যাটাস এডিট",
    //   value: "bulkPaymentStatusEdit",
    //   isChecked: permission?.bulkPaymentStatusEdit,
    //   disabled: !bpSettings?.bulkPaymentStatusEdit,
    // },
    {
      id: 22,
      label: "কাস্টমার ডিলিট",
      value: "customerDelete",
      isChecked: permission?.customerDelete,
      disabled: false,
    },
    {
      id: 23,
      label: "দৈনিক রিসেলার কাস্টমার অটো কানেকশন",
      value: "dailyResellerCustomerAutoConnection",
      isChecked: permission?.dailyResellerCustomerAutoConnection,
      disabled: false,
    },
    {
      id: 24,
      label: "গ্রাহক রিচার্জ",
      value: "customerRecharge",
      isChecked: permission?.customerRecharge,
      disabled: false,
    },
    {
      id: 25,
      label: "বাল্ক গ্রাহক প্যাকেজ এডিট",
      value: "bulkCustomerPackageUpdate",
      isChecked: permission?.bulkCustomerPackageUpdate,
      disabled: !bpSettings?.bulkPackageEdit,
    },
    {
      id: 26,
      label: "প্রমিজ তারিখ",
      value: "promiseDate",
      isChecked: permission?.promiseDate,
      disabled: false,
    },
  ];

  if (localStorage.getItem("netFee:lang") === "bn") return permissionBn;
  return permissionEn;
};
